/**
 * Created by hao.cheng on 2017/4/16.
 */

import React from 'react'
import { Row, Col, Table, Modal, Input, Select, Popover, Button, Popconfirm, Form } from 'antd'
import {EditableFormRow, EditableContext} from './EditableRow'
import EditableCell from './EditableCell'
import { Translate } from 'ns-react-i18next'
import i18n from '../../i18n.js'
import {getCameraList,addCamera,updateCamera,getUsersList} from '../../axios'

const data = []
const FormItem = Form.Item
const Option = Select.Option
const fetchLength = 100
const pageSize = 10

class Editable extends React.Component {
	state = {
		data,
		isHorizon: false,
		HorizonList: {},
		visible: false,
		ownerList: [],
		editingKey: '',
		dataLength: fetchLength,
		total: 0,
		currentPage: 1,
		loading: true,
	}
	componentDidMount(){
    getCameraList().then(r=>{
			console.log(r)
			r&&this.setState({
				data: r.cameras,
				loading: false,
				total: r.total
			})
		})
		if (this.state.ownerList.length<=0){
			getUsersList().then(r=>{
				this.setState({
					ownerList: r.users
				})
			})
		}
	}
	columns = [
		{
			title: 'id',
			dataIndex: 'id',
		},
		{
			title: 'name',
			dataIndex: 'name',
			editable: true
		},
		{
			title: 'owner',
			dataIndex: 'owner',
			editable: true,
			render: (text) => {
				return<Select placeholder="Please select a type" defaultValue={text} size="small" style={{ width: 120 }} >
			{this.state.ownerList.map((i,index)=>
			 <Option value={i.userID} key={index} disabled>{i.userName}</Option>
			)}
    </Select>
			}
		},
		{
			title: 'serialNumber',
			dataIndex: 'serialNumber',
			editable: true
		},
		{
			title: 'hardwareModel',
			dataIndex: 'hardwareModel',
		},
		{
			title: 'hardwareVersion',
			dataIndex: 'hardwareVersion',
		},
		{
			title: 'firmware',
			dataIndex: 'firmware',
		},
		{
			title: 'firmwareShort',
			dataIndex: 'firmwareShort',
		},
		{
			title: 'operation',
			dataIndex: 'operation',
			width: 190,
			render: (text, record) => {
				const editable = this.isEditing(record);
				return (
					<div>
						{editable ? (
							<span>
								<EditableContext.Consumer>
									{form => (
										<Button
											onClick={() => this.save(form, record.id)}
											style={{ marginRight: 8 }}
										>
											Save
										</Button>
									)}
								</EditableContext.Consumer>
								<Popconfirm
									title="Sure to cancel?"
									onConfirm={() => this.cancel(record.id)}
								>
									<Button>Cancel</Button>
								</Popconfirm>
							</span>
						) : (
								<Button onClick={() => this.edit(record.id)}>Edit</Button>
							)}
					</div>
				);
			},
		},
	]

	isEditing = (record) => {
		return record.id === this.state.editingKey;
	}
	edit(key) {
		// console.log(key)
		this.setState({ editingKey: key });
	}
	save(form, key) {
		form.validateFields((error, row) => {
			if (error) {
				return
			}
			let newData = [...this.state.data]
			let editingKey = ''
			const index = newData.findIndex(item => key === item.id);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				// console.log('add',item,row)
				console.log('will updata',index,item,row)
				// if (this.isHorizon(row.serialNumber))

					updateCamera(item.id,{
						sn: row.serialNumber,
						name: row.name,
						owner: row.owner
					}).then(r=>{
						this.setState({ data: newData, editingKey })
					})
					.catch(err=>{
						editingKey = item.id
						console.log(item.id,err)
					})

			} else {
				newData.push(data)
				this.setState({ data: newData, editingKey});
			}

		});
	}
	handleOk = () => {
		this.setState({
      visible: false
    })
	}
	handleCancel = () => {
		this.setState({
      visible: false
    })
	}
	cancel = (id) => {
		// console.log(id)
		let newData = [...this.state.data]
		this.setState({ data: newData,editingKey: '' });
	}
	handleHorizonSubmit = (e) =>{
		e.preventDefault()
		this.props.form.validateFields(['horizon_password'],(err,values)=>{
				if (!err) {
					// console.log('values', values)
					addCamera({
						...this.state.HorizonList,
						password: values.horizon_password,
					}).then(()=>
						getCameraList().then(r=>
							r&&this.setState({
								visible: false,
								data:r.cameras,
								editingKey: ''
							}))
					).catch(err=>console.log(err))
				}
		})
	}
	handleSubmit = (e) => {
		e.preventDefault()
		this.props.form.validateFields(['sn','name','password'],(err, values) => {
				const user = JSON.parse(localStorage.getItem('user'))
				if (!err&&user) {
					values.owner = user.user.userID
					// console.log('values', values)
					addCamera(values).then(()=>
						getCameraList().then(r=>{
							this.props.form.setFieldsValue({'password':''})
							r&&this.setState({data:r.cameras})
						})
					).catch(err=>console.log(err))
				}
		})
	}

	onSnChange = (e) => {
		e.preventDefault();
		// console.log(e.target.value.toUpperCase().slice(0,2))
		this.setState({
			isHorizon: this.isHorizon(e.target.value)
		})
	}
	isHorizon = (sn) => {
		return sn.toString().toUpperCase().slice(0,2) === 'C9'
	}
	handleSearch = (e) => {
		e.preventDefault();
		this.props.form.validateFields(['search_sn'],(err, values) => {
				if (!err) {
					console.log('values',values)
					getCameraList(values.search_sn).then(r=>{
						console.log(r)
						r &&
              this.setState({
                data: r.cameras,
                total: r.total,
              })
					})
				}
		})
	}
	tableOnChange = (page,size) =>{
		console.log(page, size)
		this.setState({
			currentPage: page
		})
		const { dataLength } = this.state
			this.setState({
				loading: true
			})
			const count = Math.ceil((page * size - dataLength) / fetchLength)
			// console.log('before data',this.state.data,count)
			getCameraList('', dataLength, count * fetchLength).then(r => {
				// console.log(this.state.data, dataLength, count*fetchLength,r.vehicles)
				if (r) {
					this.setState({
						data: this.state.data.concat(r.cameras),
						dataLength: dataLength + count * fetchLength,
						loading: false
					}, () => {
						// console.log('after data',this.state.data)
					})
				}
			})
	}
	render() {
		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell,
			},
		};

		const columns = this.columns.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: record => ({
					record,
					inputType: 'text',
					dataIndex: col.dataIndex,
					title: col.title,
					userlist: this.state.ownerList,
					editing: this.isEditing(record),
				}),
			};
		});
		const { getFieldDecorator } = this.props.form
		const { isHorizon,visible,loading,total } = this.state
		return (
			<>
			<Row>
				<Col className="gutter-row" span={6} >
						<Popover placement="rightBottom" title={i18n.t("添加相机")} content={
					<Form onSubmit={this.handleSubmit} style={{maxWidth: '300px'}}>
						<FormItem>
								{getFieldDecorator('sn', {
										rules: [{ required: true, message: 'Input sn!' }],
								})(
										<Input placeholder="sn" onChange={this.onSnChange} />
								)}
						</FormItem>
						<FormItem>
								{getFieldDecorator('name', {
									rules: [{ required: true, message: 'Input name!' }],
								})(
										<Input placeholder="name" />
								)}
						</FormItem>
						<FormItem>
								{getFieldDecorator('password', {
									rules: [{ required: isHorizon, message: 'Input WIFI Password!' }],
								})(
										<Input placeholder="password" disabled={!isHorizon} />
								)}
						</FormItem>
						<FormItem>
								<Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
										<Translate>添加</Translate>
								</Button>
						</FormItem>
					</Form>
					} trigger="click" >
							<Button type="primary" style={{ marginBottom: 16 }}><Translate>添加相机</Translate></Button>
					</Popover>
				</Col>
				<Col className="gutter-row" span={12} offset={6} >
				<Form layout="inline" onSubmit={this.handleSearch} style={{float:"right"}}>
					<FormItem>
						{getFieldDecorator('search_sn', {
							rules: [{ message: 'Please input sn!' }],
						})(<Input placeholder="Search by sn" />)}
					</FormItem>
					<FormItem>
								<Button style={{ marginRight: -16 }} type="primary" htmlType="submit"><Translate>搜索</Translate></Button>
					</FormItem>
				</Form>
				</Col>
			</Row>
			<Modal
          title="Horizon Wi-Fi密码验证"
          visible={visible}
          onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer = {null} >
				<p>输入Horizon的Wi-Fi密码以验证</p>
          <Form onSubmit={this.handleHorizonSubmit} >
						<FormItem>
								{getFieldDecorator('horizon_password', {
										rules: [{ required: true, message: '请输入WiFi密码!' }],
								})(
										<Input placeholder="WiFi password" />
								)}
						</FormItem>
						<FormItem>
								<Button type="primary" htmlType="submit" className="login-form-button" style={{width: '100%'}}>
										验证
								</Button>
						</FormItem>
					</Form>
        </Modal>
			<Table
				rowKey="id"
				components={components}
				bordered
				dataSource={this.state.data}
				columns={columns}
				rowClassName="editable-row"
				loading={loading}
				scroll={{x:true}}
				pagination={{
					onChange:this.tableOnChange,
					pageSize,
					total
				}}
			/>
			</>
		);
	}
}
const EditableTable = Form.create()(Editable);
export default EditableTable