import React from 'react'
import moment from 'moment'
import { Col,Card,DatePicker,Select,Spin,Table } from 'antd'
import {scaleLinear} from 'd3-scale'
import {interpolateHcl} from 'd3-interpolate'
import {rgb} from 'd3-color'
// import { ResponsiveHeatMapCanvas } from '@nivo/heatmap'
import * as S from '../axios'

const { RangePicker } = DatePicker
const Option = Select.Option

const colorNum = localStorage.getItem('@primary-color') || '#4A90E2'
const color = scaleLinear().domain([0,25])
  .interpolate(interpolateHcl)
  .range([rgb('rgb(253, 254, 255)'),rgb(colorNum)])

var colors = []
for (let i=0;i<=25;i++) {
  colors[i] = color(i)
}

const nameSpace = {
  pointT: {
    name:'轨迹点数',
    unit:'个'
  },
  picNumT: {
    name:'图片数',
    unit:'个'
  },
  trackT: {
    name:'轨迹流量',
    unit:'MB'
  },
  picSizeT: {
    name:'图片大小',
    unit:'MB'
  }
}
// console.log(colors)
export default class Camera extends React.Component {
  state = {
    flowData:[],
    flowTo: 'baiduFlowData',
    keys:[],
    cameraNum: 0,
    sum:{}
  }

  componentDidMount(){
    this.getData('baiduFlowData',+ new Date('2018-12-06'),+ new Date())
  }
  getData = (request,from,to) => {
    this.setState({loading:true})
    S[request](from,to).then(r=>{
      console.log(r)
      // var dateObj = {}
      let flowData = r.infos.sort((a,b)=> b.dataFlow-a.dataFlow)

      this.setState({
        flowData,
        // keys: Object.keys(dateObj).sort((a,b)=>new Date(a) - new Date(b)),
        cameraNum:r.infos.length,
        // dateLength:  Object.keys(dateObj).length,
        loading: false,
        sum:{
          pointT: r.pointT,
          trackT: r.trackT,
          picNumT: r.picNumT,
          picSizeT: r.picSizeT,
        }
      })
    })
  }
  disabledDate = (current) =>{
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }
  onChange = (date,dateString) =>{
    console.log(date,dateString)
    const {flowTo} = this.state
    console.log(flowTo)
    if (date[0]&&date[1]){
      this.getData(flowTo,+ new Date(date[0]),+ new Date(date[1]))
    }
  }
  flowToChange = (value) =>{
    console.log(value)
    this.setState({
      flowTo:value
    })
  }
  sortBy = (key) => {
    return (a,b) => a[key] - b[key]
  }
  rowKey = 0
  render(){
    const { flowData, loading, sum} = this.state
  const columns = [{
      title: 'cameraSN',
      dataIndex: 'cameraSN',
      key: 'cameraSN',
      width: 120
    },{
      title: 'date',
      dataIndex: 'date',
      key: 'date',
      sortDirections: ['descend', 'ascend'],
      sorter: this.sortBy('date')
    },{
      title: 'points',
      dataIndex: 'points',
      key: 'points',
      sortDirections: ['descend', 'ascend'],
      sorter: this.sortBy('points')
    },{
      title: 'trackFlow',
      dataIndex: 'trackFlow',
      key: 'trackFlow',
      sortDirections: ['descend', 'ascend'],
      sorter: this.sortBy('trackFlow')
    },{
      title: 'picNum',
      dataIndex: 'picNum',
      key: 'picNum',
      sortDirections: ['descend', 'ascend'],
      sorter: this.sortBy('picNum')
    },{
      title: 'picSize',
      dataIndex: 'picSize',
      key: 'picSize',
      sortDirections: ['descend', 'ascend'],
      sorter: this.sortBy('picSize')
    }
  ]
  // console.log(flowData,keys)
   return <Col className="gutter-row" md={24}>
   <div className="gutter-box">
    <Card title="上传流量" bordered={false}>
    <Select defaultValue="baiduFlowData" style={{ width: 100 }} onChange={this.flowToChange}>
      <Option value="baiduFlowData">Baidu</Option>
    </Select>
    <RangePicker onChange={this.onChange}
      disabledDate={this.disabledDate}
      defaultValue = {[moment('2018-12-06'),moment()]}
      />
      <div style={{padding:'12px 0'}}>

         {Object.keys(sum).map((i,index)=>(
           <span key={index}>{nameSpace[i].name}:<b>{sum[i] + nameSpace[i].unit}</b>&nbsp;|&nbsp;</span>
           ))}
      </div>
      <Spin tip="Loading..." spinning={loading}>
        {/* <div style={{
          position:'absolute',
          marginTop: '24px',
          zIndex: 10,
          background: 'rgba(255,255,255,0.8)',
          paddingRight: '16px'
        }}>
          {flowData.map((i,index)=>{
            return<div key={index} style={{
              display: 'block',
              height: cellSize - 0.35 + 'px',
              lineHeight: cellSize - 0.35 + 'px',
            }}>
              <span style={{
                display: 'inline-block',
                width: '80px',
                color: '#999'
              }}>{i.sn}</span>
              <span>{parseInt(i.sum/1000000).toFixed(1)}</span>
            </div>
          })}
        </div>
        <div style={{
          padding: '0 20px 20px 0',
          overflow: 'scroll'
        }}>
        <div style={{
          position: 'relative',
          width: dateLength*cellSize + 80 + 'px',
          height: cameraNum*cellSize + 102 + 'px'
        }}>
        <ResponsiveHeatMapCanvas
          data = {flowData}
          keys={keys}
          colors={colors}
          indexBy="sn"
          margin={{
            "top": 24,
            "right": 0,
            "bottom": 80,
            "left": 140
          }}
          // cellShape="circle"
          axisTop={null}
          axisLeft={null}
          axisBottom={{
            "orient": "bottom",
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": -90,
            "legend": "sn",
            "legendPosition": "middle",
            "legendOffset": 36
          }}
          pixelRatio={1}
          labelTextColor='inherit:darker(0.6)'
          cellHoverOthersOpacity={0.2}
          />
        </div>
        </div> */}
        <Table bordered
          rowKey={()=>this.rowKey++}
          columns={columns}
          dataSource={flowData} />
        </Spin>
      </Card>
    </div>
   </Col>
  }
}