/**
 * Created by hao.cheng on 2017/4/16.
 */
import axios from 'axios';
import { get,post,getData,postData } from './tools';
import * as config from './config';

const eHi_api = process.env.SITE_URL === 'admin-beta.waylens.com'
    ? 'http://test.1hai.waylens.cn:9000'
    : 'https://1hai.waylens.cn/ehi'

const baidu = process.env.SITE_URL === 'admin-beta.waylens.com'
    ? 'http://test.1hai.waylens.cn:9002'
    : 'https://1hai.waylens.cn/baidu'

export const getPros = () => axios.post('http://api.xitu.io/resources/github', {
    category: "trending",
    period: "day",
    lang: "javascript",
    offset: 0,
    limit: 30
}).then(function (response) {
    return response.data;
}).catch(function (error) {
    console.log(error);
});

export const npmDependencies = () => axios.get('./npm.json').then(res => res.data).catch(err => console.log(err));

export const weibo = () => axios.get('./weibo.json').then(res => res.data).catch(err => console.log(err));

const GIT_OAUTH = 'https://github.com/login/oauth';
export const gitOauthLogin = () => axios.get(`${GIT_OAUTH}/authorize?client_id=792cdcd244e98dcd2dee&redirect_uri=http://localhost:3006/&scope=user&state=reactAdmin`);
export const gitOauthToken = code => axios.post('https://cors-anywhere.herokuapp.com/' + GIT_OAUTH + '/access_token', {...{client_id: '792cdcd244e98dcd2dee',
    client_secret: '81c4ff9df390d482b7c8b214a55cf24bf1f53059', redirect_uri: 'http://localhost:3006/', state: 'reactAdmin'}, code: code}, {headers: {Accept: 'application/json'}})
    .then(res => res.data).catch(err => console.log(err));
export const gitOauthInfo = access_token => axios({
    method: 'get',
    url: 'https://api.github.com/user?access_token=' + access_token,
}).then(res => res.data).catch(err => console.log(err));

// easy-mock数据交互
// 管理员权限获取
export const admin = () => get({url: config.MOCK_AUTH_ADMIN});

// 访问权限获取
export const guest = () => get({url: config.MOCK_AUTH_VISITOR});

export const login = (params) => {
  // console.log('login params',params)
  return post({url:eHi_api + '/api/users/signin', data:params})
}
export const getOnlineStatus = sn =>{
    return getData({
        url:eHi_api + `/api/devices/${sn}/online_state`,
        // url:eHi_api + `/internal/devices/${sn}/online_state`,
        data: null
    })
}

export const getCameraList = (sn='', from, count) => {
    from = from || 0
    count = count || 100
    return getData({
        url:eHi_api + `/api/devices?sn=${sn}&cursor=${from}&count=${count}`,
        data: null
    })
}
export const getUsersList = (sn='') => {
    return getData({
        url:eHi_api + '/api/users',
        data: null
    })
}
export const getOnlineTotal = () => {
    return getData({
        url:eHi_api + '/api/vehicles/online_total',
        data: null
    })
}
export const addCamera = (data) => {
    return postData({
        url:eHi_api + '/api/devices',
        data
    })
}
export const updateCamera = (id,data) => {
    return postData({
        url:eHi_api + '/api/devices/' + id,
        data
    })
}
export const CameraLogPost = (sn) => {
    return postData({
        url:eHi_api + `/api/devices/${sn}/upload`,
        data: {
        "cmd": "start",
        "uploadMode": "CameraLog"
        }
    })
}

export const getVehicleList = (key='', cursor=0, count=100) => {
    return getData({
        url:eHi_api + `/api/vehicles?key=${key}&cursor=${cursor}&count=${count}`,
        data:null
    })
}

export const addVehicle = (data) => {
  return postData({
    url:eHi_api + '/api/vehicles',
    data
  })
}

export const editVehicle = (id,data) => {
  return postData({
    url:eHi_api + `/api/vehicles/${id}`,
    data
  })
}
export const cityList = () => {
  return getData({
    url:eHi_api + '/api/areas',
  })
}
export const baiduFlowData = (from,to) => {
  return getData({
    url:baidu + `/api/baidu/dataflows?from=${from}&to=${to}`,
  })
}
