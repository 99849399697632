import * as i18n from 'i18next'
// import * as translation from './translations.json'
import LngDetector from 'i18next-browser-languagedetector'

i18n
  .use(LngDetector)
  .init({
    resources: {
      "en": {
        "common": {
          "相机信息": "Camera",
          "车辆信息": "Vehicles",
          "相机列表": "Cameras List",
          "车辆列表": "Vehicles List",
          "添加车辆": "Add Vehicle",
          "添加相机": "Add Camera",
          "列表排序": "Sorted",
          "总共在线": "Total Online",
          "搜索": "Search",
          "添加": "Add",
          "Camera": "Camera"
        },
        "specificNs": {}
      },
      "cn": {
        "common": {
          "相机信息": "相机信息",
          "车辆信息": "车辆信息",
          "添加相机": "添加相机",
          "添加车辆": "添加车辆",
          "搜索": "搜索",
        },
        "specificNs": {}
      }
    },
    fallbackLng: {
      'en-US': ['en'],
      'fr-FR': ['fr'],
      default: ['cn'],
    },
    debug: process.env.SITE_URL === 'admin-beta.waylens.com',
    // have a comnpmmon namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        if (format === 'number') {
          if (lng === 'cn') {
            return value >= 10000
              ? (value / 10000).toFixed(1) + '万'
              : value
          } else {
            return value >= 1000
              ? (value / 1000).toFixed(1) + 'K'
              : value
          }
        }
        if (format === 'Miles') {
          if (lng === 'cn') {
            return value >= 10000
              ? (value / 10000).toFixed(1) + '万'
              : value
          } else {
            value = value * 0.6214
            return value >= 1000
              ? (value / 1000).toFixed(1) + 'K'
              : value.toFixed(1)
          }
        }
        if (format === 'MilesNum') {
          if (lng === 'cn') {
            return value
          } else {
            value = value * 0.6214
            return value.toFixed(1)
          }
        }
        // if (value instanceof Date) return getFormDate(value)

        return value
      }
    },
    react: {
      wait: true,
    },
    keySeparator: false, // we use content as keys
  }, function (err, t) {
    // initialized and ready to go!
    console.log(err)
  })

export default i18n
