import React from 'react'
import { Input, InputNumber, Select,Checkbox, Form } from 'antd'
import { EditableContext } from './EditableRow'
import { cityList } from '../../axios';

const FormItem = Form.Item
const Option = Select.Option

export default class EditableCell extends React.Component {
	state = {
		ownerList: []
	}

	getInput = () => {
		const {inputType,dataIndex,record,userlist,vehicle_type,citylist} = this.props
		// console.log('citylist',citylist)
		// if (inputType === 'object') console.log('object',record[dataIndex])
		if (dataIndex === 'owner') {
			return <Select placeholder="Please select a type" style={{ width: 120 }} >
				{userlist.map((i,index)=>
				<Option value={i.userID} key={index}>{i.userName}</Option>
				)}
			</Select>
		}
		if (inputType === 'number') {
			return <InputNumber />
		}
		if (dataIndex === 'mainCamera'&&!record.id.toString().includes('new')) {
			return <Input disabled />
		}
		if (dataIndex === 'serialNumber') {
			return <Input disabled />
		}
		if (dataIndex === 'feedDataToBaidu'||dataIndex === 'feedDataToWayz') {
			return <Checkbox defaultChecked={record[dataIndex]} />
		}
		if (dataIndex === 'vehicleType') {
			return <Select placeholder="Please select a type" style={{ width: 120 }} >
			{Object.keys(vehicle_type).map((i,index)=>
			 <Option value={i} key={index}>{vehicle_type[i]}</Option>
			)}
    </Select>
		}
		if (dataIndex === 'attribution') {
			return <Select placeholder="Please select a city" style={{ width: 120 }} >
			{citylist.map((i,index)=>
			 <Option value={i.code} key={index}>{i.name}</Option>
			)}
    </Select>
		}
		return <Input />
	}
	optionList = (index) => {
		switch (index) {
			case 'phone':
					return false
			case 'secondaryCamera':
					return false
			default:
					return true;
		}
	}
	render() {
		const {
			editing,
			dataIndex,
			title,
			inputType,
			record,
			index,
			...restProps
		} = this.props;
		return (
			<EditableContext.Consumer>
				{(form) => {
					const { getFieldDecorator } = form;
					return (
						<td {...restProps}>
							{editing ? (
								<FormItem style={{ margin: 0 }}>
									{getFieldDecorator(dataIndex, {
										rules: [{
											required: this.optionList(dataIndex),
											message: `Please Input ${title}!`,
										}],
										initialValue: typeof record[dataIndex]==='object'? record[dataIndex]&&record[dataIndex].sn : record[dataIndex],
									})(this.getInput())}
								</FormItem>
							) : restProps.children}
						</td>
					);
				}}
			</EditableContext.Consumer>
		);
	}
}
