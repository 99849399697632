/**
 * Created by 叶子 on 2017/7/30.
 * http通用工具函数
 */
import axios from 'axios';
import { message } from 'antd';

/**
 * 公用get请求
 * @param url       接口地址
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const get = ({url, msg = '接口异常', headers}) =>
    axios.get(url, headers).then(res => res.data).catch(err => {
       console.log(err.response);
       message.warn(err.response);
    });

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param msg       接口异常提示
 * @param headers   接口所需header配置
 */
export const post = ({url, data, headers}) =>{
    // console.log('data',url,data)
    return axios.post(url, data, headers).then(res => res.data).catch(err => {
        console.log(err);
        console.log(err.response);
        if (err.response){
            message.warn(err.response.statusText)
        }else{
            message.warn(err)
        }
    })};

export const postData = ({url,data}) => {
    return axios({
        method:'post',
        url,
        headers:{
        "X-Auth-Token": JSON.parse(localStorage.getItem('user')).token,
        },
        data,
    }).then(res => {
        message.success('更新成功')
        console.log(res.data)
        return res.data
    }).catch(err => {
        if (err.response){
            console.log(err.response.data);
            message.warn(err.response.data.msg);
        }else{
            console.log(err);
            message.warn(err.message);
        }
        throw Error(err)
    })
}
export const getData = ({url,data}) => {
    return axios({
        method:'get',
        url,
        headers:{
        "X-Auth-Token": JSON.parse(localStorage.getItem('user')).token,
        },
        data,
    }).then(res => {
        // message.success('更新成功')
        // console.log(res.data)
        return res.data
    }).catch(err => {
        console.log('err',err.response)
        if (err.response.data.code === 23) {
            // alert('23')
            localStorage.removeItem('user')
            window.location.href = "/"
        }
        if (err.response){
            if (err.response.status === 404){
                message.warn(err.response.statusText)
                return
            }
            console.log(err.response.data);
            message.warn(err.response.data.msg);
        }else{
            console.log(err);
            message.warn(err.message);
        }
        console.log(err)
    })
}