import React from 'react'
import {Col, Card } from 'antd'
import EditableTableCamera from './tables/EditableTable'
import i18n from '../i18n'

export default class Camera extends React.Component {
  render(){
    return (
    <Col className="gutter-row" md={24}>
        <div className="gutter-box">
          <Card title={i18n.t('相机列表')} bordered={false}>
                <EditableTableCamera />
            </Card>
        </div>
    </Col>
    )
  }
}