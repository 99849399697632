/**
 * Created by hao.cheng on 2017/4/16.
 */

import React from 'react';
import { Row, Col, Form, Menu, message, Input, Icon, Checkbox, Table, Popconfirm, Dropdown, Button } from 'antd';
import { EditableFormRow, EditableContext } from './EditableRow';
import EditableCell from './EditableCell';
import { Translate } from 'ns-react-i18next';

import {
	getVehicleList,
	getOnlineStatus,
	addVehicle,
	editVehicle,
	cityList,
	CameraLogPost,
	getOnlineTotal
} from '../../axios'

const data = [];
const FormItem = Form.Item;
const fetchLength = 100
const pageSize = 10

const typeTable = {
	'id': 'number',
	'mainCamera': 'object',
	'secondaryCamera': 'object',
	'feedDataToBaidu': 'boolean'
}
const vehicle_type = {
	1001: "出租车",
	1002: "网约车",
	2001: "物流车-城内",
	2002: "物流车-城际",
	3001: "租赁车",
	4001: "救护车",
	9000: "其他",
}

class Vehicle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data,
			loading: true,
			editingKey: '',
			dataLength: fetchLength,
			total: 0,
			onlineTotal: 0,
			currentPage: 1,
			cityList: [],
			columns: []
		}
		this.columns = []
	}

	componentDidMount() {
		cityList().then(r => {
			// console.log(r)
			var listObj = {}
			r.areas.forEach(i => {
				listObj[i.code] = i.name
			})
			// console.log(listObj)
			this.setState({
				cityList: r.areas,
				listObj
			})
		})
		getOnlineTotal().then(r => {
			// console.log(r)
			this.setState({
				onlineTotal: r.onlineTotal
			})
		})
		getVehicleList().then(r => {
			console.log('getVehicleList', r)

			if (r) {
				this.columns = Object.keys(r.vehicles[0]).map(i => {
					return {
						title: i,
						dataIndex: i,
						editable: false,
					}
				})
				console.log(this.colums)
				this.setState({
					data: r.vehicles,
					loading: false,
					total: r.total,
				})
			}

			var optional = [
				{
					title: 'Status',
					dataIndex: 'isOnline',
					editable: false,
					sortDirections: ['descend', 'ascend'],
					sorter: (a, b) => a.isOnline - b.isOnline,
					render: (text, record) => {
						// console.log(text)
						// return <Tag color={this.state[this.formObj(record.mainCamera)]?'blue':''}>{this.state[this.formObj(record.mainCamera)]?'Online':'Offline'}</Tag>
						const isOnline = text
						return <Dropdown disabled={!isOnline} overlay={
							<Menu onClick={(e) => this.handleMenuClick(e, record)}>
								<Menu.Item key="1">Post Log</Menu.Item>
							</Menu>
						}>
							<Button type={isOnline ? 'primary' : ''} size="small">
								{isOnline ? 'Online' : 'Offline'}
								{isOnline && <Icon type="down" />}
							</Button>
						</Dropdown>
					}
				},
				{
					title: 'company',
					dataIndex: 'company',

					editable: true,
				},
				{
					title: '城市',
					dataIndex: 'attribution',
					editable: true,
					render: (text) => <span>{this.state.listObj[text] || ''}</span>
				},
				{
					title: 'driver',
					dataIndex: 'driver',
					editable: true,
				},
				{
					title: 'phone',
					dataIndex: 'phone',
					editable: true,
				},
				{
					title: 'plateNumber',
					dataIndex: 'plateNumber',
					editable: true,
				},
				{
					title: 'Vehicle Type',
					dataIndex: 'vehicleType',
					editable: true,
					render: (text) => <span>{vehicle_type[text]}</span>
				},
				{
					title: 'mainCamera',
					dataIndex: 'mainCamera',
					editable: true,
					render: (text) => <span>{this.formObj(text)}</span>
				},
				// {
				// 	title: 'secondaryCamera',
				// 	dataIndex: 'secondaryCamera',
				// 	editable: true,
				// 	require: false,
				// 	render: (text) => <span>{this.formObj(text)}</span>
				// },
				{
					title: 'Baidu',
					dataIndex: 'feedDataToBaidu',

					editable: true,
					render: (text) => <Checkbox defaultChecked={text} disabled />
				},
				{
					title: 'Wayz',
					dataIndex: 'feedDataToWayz',
					editable: true,
					render: (text) => <Checkbox defaultChecked={text} disabled />
				}
			]

			for (let c in this.columns) {
				for (let o in optional) {
					if (this.columns[c].dataIndex === optional[o].dataIndex) {
						// console.log(this.columns[c], optional[o])
						this.columns.splice(c, 1, optional[o])
					}
				}
			}
			this.columns.push({
				title: 'operation',
				dataIndex: 'operation',
				width: 190,
				render: (text, record) => {
					const editable = this.isEditing(record);
					return (
						<div>
							{editable ? (
								<span>
									<EditableContext.Consumer>
										{form => (
											<Button
												onClick={() => this.save(form, record.id)}
												style={{ marginRight: 8 }}
											>
												Save
                      </Button>
										)}
									</EditableContext.Consumer>
									<Popconfirm
										title="Sure to cancel?"
										onConfirm={() => this.cancel(record.id)}
									>
										<Button>Cancel</Button>
									</Popconfirm>
								</span>
							) : (
									<Button onClick={() => this.edit(record.id)}>Edit</Button>
								)}
						</div>
					);
				},
			})
			// console.log(this.columns)
			this.setState({
				columns: this.columns
			})
		})
		window.refreshOnlineState = setInterval(() => this.updateOnlineState(this.state.currentPage), 120000)
	}
	componentWillUnmount() {
		clearInterval(window.refreshOnlineState)
	}
	handleMenuClick = (e, record) => {
		console.log(e, record)
		CameraLogPost(record.mainCamera.sn).then(r => console.log(r)).catch(err => console.log(err))
	}
	updateOnlineState = (pageNum) => {
		const { data } = this.state
		message.info('Updating Online Status', 1)
		getOnlineTotal().then(r => {
			// console.log('onlineTotal',r.onlineTotal)
			this.setState({
				onlineTotal: r.onlineTotal
			})
		})
		Promise.all(data.map(async (d, index) => {
			if (index >= (pageNum - 1) * pageSize && index <= pageNum * pageSize) {
				await getOnlineStatus(d.mainCamera.sn).then(r => d.isOnline = r.isOnline)
				// if (d.isOnline) console.log(d.plateNumber)
				return d
			} else {
				return d
			}
		})).then(data => this.setState({
			data,
			updatingOnlineState: false
		}))
	}
	tableOnChange = (page, size) => {
		// console.log(page, size)
		this.setState({
			currentPage: page
		})
		const { dataLength } = this.state
		if (page * size > dataLength) {
			this.setState({
				loading: true
			})
			const count = Math.ceil((page * size - dataLength) / fetchLength)
			// console.log('before data',this.state.data,count)
			getVehicleList('', dataLength, count * fetchLength).then(r => {
				// console.log(this.state.data, dataLength, count*fetchLength,r.vehicles)
				if (r) {
					this.setState({
						data: this.state.data.concat(r.vehicles),
						dataLength: dataLength + count * fetchLength,
						loading: false
					}, () => {
						// console.log('after data',this.state.data)
					})
				}
			})
		} else {
			this.updateOnlineState(page)
		}
	}

	isEditing = (record) => {
		return record.id === this.state.editingKey;
	}

	isOnline = (sn) => {
		getOnlineStatus(sn).then(r => {
			// r.isOnline&&console.log(r)
			r && this.setState({
				[sn]: r.isOnline
			})
		})
	}

	edit(key) {
		// console.log(key)
		this.setState({ editingKey: key });
	}

	save(form, key) {
		form.validateFields((error, row) => {
			if (error) {
				return;
			}
			let newData = [...this.state.data]
			let editingKey = ''
			const index = newData.findIndex(item => key === item.id);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				// console.log('add',item,row)
				if (item.id.toString().includes('new')) {
					console.log('will add', row)
					addVehicle(this.formData(row))
						.then(r => {
							this.setState({ data: newData, editingKey })
							console.log(r)
						})
						.catch(err => {
							newData.splice(index, 1)
							// console.log(newData,index)
							this.setState({ data: newData, editingKey })
							console.log(err)
						})
				} else {
					console.log('will updata', row)
					editVehicle(item.id, this.formData(row))
						.then(r => {
							this.setState({ data: newData, editingKey })
						})
						.catch(err => {
							editingKey = item.id
							console.log(item.id, err)
						})
				}
				// this.setState({ data: newData, editingKey: '' });
			} else {
				newData.push(data);
				this.setState({ data: newData, editingKey });
			}

		});
	}
	formData = (row) => {
		console.log(row.feedDataToBaidu)
		if (typeof row.feedDataToBaidu !== 'boolean') row.feedDataToBaidu = row.feedDataToBaidu.toLowerCase() === 'true'
		if (row.secondaryCamera === '') delete row.secondaryCamera
		return row
	}
	formObj = (text) => {
		if (typeof text === 'object') return text && text.sn
		return text
	}
	cancel = (id) => {
		console.log(id)
		let newData = [...this.state.data]
		if (id.toString().includes('new')) {
			const index = newData.findIndex(item => id === item.id);
			if (newData[index].mainCamera === '' || newData[index].plateNumber === '') newData.splice(index, 1);
		}
		this.setState({ data: newData, editingKey: '' });
	};
	i = 0
	handleAdd = () => {
		const { data } = this.state;

		const newData = {
			id: 'new' + this.i++,
			company: '',
			driver: '',
			plateNumber: '',
			vehicleType: '',
			mainCamera: '',
			secondaryCamera: '',
			feedDataToBaidu: false
		};
		this.setState({
			data: [newData, ...data],
		})
		this.edit(newData.id)
	}
	handleSearch = (e) => {
		e.preventDefault();
		this.props.form.validateFields(['plateNumber'], (err, values) => {
			if (!err) {
				console.log('values', values)
				getVehicleList(values.plateNumber).then(r =>
					r && this.setState({
						data: r.vehicles,
						total: r.total
					})
				)
			}
		})
	}
	render() {
		const components = {
			body: {
				row: EditableFormRow,
				cell: EditableCell,
			},
		};

		const columns = this.state.columns.map((col) => {
			if (!col.editable) {
				return col;
			}
			return {
				...col,
				onCell: record => ({
					record,
					inputType: typeTable[col.dataIndex] ? typeTable[col.dataIndex] : 'text',
					dataIndex: col.dataIndex,
					title: col.title,
					vehicle_type,
					citylist: this.state.cityList,
					editing: this.isEditing(record),
				}),
			};
		});
		const { getFieldDecorator } = this.props.form
		const { loading, data, total, onlineTotal } = this.state
		return (
			<>
				<Row>
					<Col className="gutter-row" span={6}>
						<Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
							<Translate>添加车辆</Translate>
						</Button>
					</Col>
					<Col span={5} offset={1} style={{ textAlign: "right" }}> {data.filter(d => d.isOnline).length + '/' + onlineTotal}
						&nbsp;&nbsp;&nbsp;&nbsp;<Translate>列表排序</Translate> /<Translate>总共在线</Translate>
					</Col>
					<Col className="gutter-row" span={12} >

						<Form layout="inline" onSubmit={this.handleSearch} style={{ float: "right" }}>
							<FormItem>
								{getFieldDecorator('plateNumber', {
									rules: [{ message: 'Please input plateNumber or sn!' }],
								})(<Input placeholder="plateNumber or sn" />)}
							</FormItem>
							<FormItem>
								<Button style={{ marginRight: -16 }} type="primary" htmlType="submit"><Translate>搜索</Translate></Button>
							</FormItem>
						</Form>
					</Col>
				</Row>
				<Table
					rowKey="id"
					components={components}
					bordered
					dataSource={data}
					columns={columns}
					rowClassName="editable-row"
					loading={loading}
					scroll={{ x: true }}
					pagination={{
						onChange: this.tableOnChange,
						pageSize,
						total
					}}
				/>
			</>
		)
	}
}

const VehicleTable = Form.create()(Vehicle);
export default VehicleTable
