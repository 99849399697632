import React from 'react'
import { Col, Card } from 'antd'
import VehicleTable from './tables/VehicleTable'
import i18n from '../i18n'
export default class Vehicles extends React.Component {
  render(){
    return <Col className="gutter-row" md={24}>
    <div className="gutter-box">
        <Card title={i18n.t('车辆列表')} bordered={false}>
            <VehicleTable />
        </Card>
    </div>
</Col>
  }
}